import React, { useContext, useState } from "react";
import { Box, Divider, Link, Menu, MenuItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ConfirmationDialogActivator from "../../../components/confirmation-dialog/confirmation-dialog-activator";
import RoleTypes from "../../../enums/role-types";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { AuthContext } from "../../../context/auth";
import EditMessageModal from "./EditMessageModal";

const useStyles = makeStyles(() => {
  return {
    menuIcon: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    menuItemIcon: {
      marginRight: 10,
    },
  };
});

export default function ContextMenu({ message, setMessages }) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [editMessageModalOpened, setEditMessageModalOpened] = useState(false);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess, initSnackbarGeneric } =
    useContext(SnackbarContext);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setEditMessageModalOpened(true);
  };

  const handleDelete = () => {
    startGlobalLoader();

    API.delete(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile?.id +
        ApiUri?.MESSAGE +
        "/" +
        message.id
    ).then(
      (response) => {
        setMessages(null);
        initSnackbarSuccess("Message successfully Deleted");
        stopGlobalLoader();
        handleClose();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
        handleClose();
      }
    );
  };

  return (
    <React.Fragment>
      <MoreVertIcon onClick={handleClick} className={classes.menuIcon} />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem>
          <ConfirmationDialogActivator
            onClick={handleDelete}
            actionName="Delete"
            type="link"
          />
        </MenuItem>
      </Menu>
      {editMessageModalOpened && (
        <EditMessageModal
          message={message}
          setMessages={setMessages}
          modalOpened={editMessageModalOpened}
          handleClose={() => {
            setEditMessageModalOpened(false);
          }}
        />
      )}
    </React.Fragment>
  );
}
