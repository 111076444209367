import React, { useContext, useEffect, useState } from "react";
import GeneralSettings from "./general-settings";
import { useParams } from "react-router";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { GlobalLoaderContext } from "../../context/global-loader";
import { SnackbarContext } from "../../context/snackbar";
import ApiUri from "../../api/api-uri";
import API from "../../api/api";
import { Profile } from "../../models/profile/profile";
import ManageUsers from "./manage-users";
import H2 from "../../components/layout/titles/h2";
import SocialMedia from "./social-media";
import CommunicationSettings from "./communication-settings";
import { AuthContext } from "../../context/auth";
import Notes from "./notes";
import ChangeParent from "./change-parent";
import RoyaltySplits from "./royalty-splits";
import ProfileTypes from "../../enums/profile-types";
import Customization from "./customisation";
import PaymentSettings from "./payment-settings";
import { useHistory } from "react-router-dom";
import { TwoFactorAuthenticationModal } from "../../components/TwoFactorAuthenticationModal";
import { useTwoFactorAuthenticationTimer } from "../../context/two-factor-authentication-timer";
import Links from "./links";
import RoleTypes from "../../enums/role-types";
import { getCompleteProfile } from "../../api/utils/get/getCompleteProfile";

export default function EditProfile() {
  const { id: idFromUrl } = useParams();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [showEditProfilePage, setShowEditProfilePage] = useState(false);
  const { user, userRole } = useContext(AuthContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarError, initSnackbarSuccess } =
    useContext(SnackbarContext);
  const [
    twoFactorAuthenticationModalOpen,
    setTwoFactorAuthenticationModalOpen,
  ] = useState(false);
  const [missingFieldsState, setMissingFieldsState] = useState<string[]>([]);
  const history = useHistory();
  const { time, isRunning, startTimer, resetTimer } =
    useTwoFactorAuthenticationTimer();

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const seeThisPage =
    userRole === RoleTypes.OWNER ||
    userRole === RoleTypes.ADMIN ||
    user?.is_mmpz_service_admin;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowEditProfilePage(true);
      if (user?.twoFactorAuthenticationEnabled && (!isRunning || time === 0)) {
        setTwoFactorAuthenticationModalOpen(true);
      }
      const urlSearchParams = new URLSearchParams(window.location.search);
      const missingFields = Array.from(urlSearchParams.keys());

      if (missingFields.length > 0) {
        setMissingFieldsState(missingFields);
        setExpanded("panel1");
      }
    }
  }, []);

  let twoFactorApproved = false;

  const handleClose2FASuccess = () => {
    twoFactorApproved = true;
    console.log("approved, starting timer");
    resetTimer();
    startTimer();
  };

  const handleClose2FAError = () => {
    if (!twoFactorApproved) {
      console.log("not approved, redirecting to dashboard");
      initSnackbarError(
        "Please complete Two Factor Authentication to access the Edit Profile page"
      );
      history.push("/dashboard");
    } else {
      setTwoFactorAuthenticationModalOpen(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (profile && profile.id) {
      return;
    }

    const getProfileData = async () => {
      startGlobalLoader();
      try {
        const completeProfileResponse = await getCompleteProfile({
          profileId: idFromUrl ?? user?.last_accessed_profile.id,
        });
        const completeProfile = completeProfileResponse.data;
        if (isMounted) {
          setProfile(completeProfile);
        }
      } catch (err) {
        initSnackbarError(err);
      } finally {
        stopGlobalLoader();
      }
    };
    getProfileData();

    return () => {
      isMounted = false;
    };
  }, [profile]); // eslint-disable-line

  const reload = () => {
    setProfile(null);
  };

  return profile && showEditProfilePage ? (
    <React.Fragment>
      <H2>
        <Chip label="Profile" size="small" style={{ marginRight: "15px" }} />
        <b>{profile.profile_information.name}</b>
      </H2>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">General Settings</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <GeneralSettings
            profile={profile}
            missingFields={missingFieldsState}
            currentUserRole={userRole}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Links</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <Links profile={profile} />
        </AccordionDetails>
      </Accordion>

      {user?.last_accessed_profile.profile_type.name ===
        ProfileTypes.PUBLISHER_ADMINISTRATOR &&
        (userRole === RoleTypes.OWNER ||
          userRole === RoleTypes.ADMIN ||
          user?.is_mmpz_service_admin) && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6">Payment Settings</Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails>
              <PaymentSettings />
            </AccordionDetails>
          </Accordion>
        )}

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Manage Users</Typography>
        </AccordionSummary>
        <Divider />
        <ManageUsers profile={profile} reload={reload} />
      </Accordion>

      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Communication Settings</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <CommunicationSettings profile={profile} currentUserRole={userRole} />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">Social Media</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <SocialMedia profile={profile} currentUserRole={userRole} />
        </AccordionDetails>
      </Accordion>

      {user?.is_mmpz_service_admin ? (
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Notes</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Notes profile={profile} currentUserRole={userRole} />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {user?.is_mmpz_service_admin &&
      profile?.profile_type?.name !== ProfileTypes.SERVICE ? (
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Change Parent</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <ChangeParent profile={profile} />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {user?.is_mmpz_service_admin ? (
        <Accordion
          expanded={expanded === "panel9"}
          onChange={handleChange("panel9")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Splits</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <RoyaltySplits profile={profile} setProfile={setProfile} />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {profile.profile_type.name === ProfileTypes.SERVICE ||
      profile.profile_type.name === ProfileTypes.PUBLISHER_ADMINISTRATOR ? (
        <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6">Customisation</Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            {/* <RoyaltySplits profile={profile} setProfile={setProfile} /> */}
            <Customization currentUserRole={userRole} />
          </AccordionDetails>
        </Accordion>
      ) : null}
      <TwoFactorAuthenticationModal
        modalOpened={twoFactorAuthenticationModalOpen}
        handleClose={handleClose2FAError}
        onSuccess={handleClose2FASuccess}
      />
    </React.Fragment>
  ) : null;
}
