import React, { useContext, useEffect, useState } from "react";
import { Composition } from "../../../models/composition/composition";
// import CompositionForm from "./composition-form";
import CompositionForm from "../common-components/CompositionForm";
import SongWritersPercentageShareContextProvider from "../../../context/songwriters-percentage-share";
import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { LyricsRounded, VolumeUpRounded } from "@mui/icons-material";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ProfileTypes from "../../../enums/profile-types";
import ProductsPage from "./ProductsPage";
import RoleTypes from "../../../enums/role-types";
import { Prompt, useHistory } from "react-router-dom";

export default function AddNewSong() {
  const [composition, setComposition] = useState({} as Composition);
  // const [showProductsPage, setShowProductsPage] = useState(false);
  // const [productsAvailableForPurchase, setProductsAvailableForPurchase] =
  //   useState([]);
  // const [showContactPublisherPage, setShowContactPublisherPage] =
  //   useState(false);
  const [showAddNewSongPage, setShowAddNewSongPage] = useState(false);

  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError, initSnackbarGeneric } =
    useContext(SnackbarContext);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const checkIfHasSomeActiveProduct = (products) => {
    return products.some((product) => product.active === true);
  };

  const seeThisPage =
    (userRole === RoleTypes.OWNER ||
      userRole === RoleTypes.ADMIN ||
      userRole === RoleTypes.EDITOR ||
      user?.is_mmpz_service_admin) &&
    user?.last_accessed_profile.profile_type.name === ProfileTypes.SONGWRITER;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowAddNewSongPage(true);
      startGlobalLoader();
      API.get(ApiUri.PROFILE + "/" + user?.last_accessed_profile.id).then(
        (response) => {
          const profileObject = response.data;
          const allProductsFromProfile = profileObject.package_song_writers;
          const hasAtLeastOneActiveProduct = checkIfHasSomeActiveProduct(
            allProductsFromProfile
          );
          if (allProductsFromProfile.length > 0 && hasAtLeastOneActiveProduct) {
            stopGlobalLoader();
          } else {
            API.get(ApiUri.PROFILE + "/" + profileObject.parent.id).then(
              (response) => {
                const parentProfile = response.data;
                const allProductsFromParentProfile =
                  parentProfile.package_song_writers;
                if (
                  allProductsFromParentProfile.length > 0 &&
                  checkIfHasSomeActiveProduct(allProductsFromParentProfile) &&
                  parentProfile.profile_type.name === ProfileTypes.PUBLISHER
                ) {
                  stopGlobalLoader();
                } else if (
                  parentProfile.profile_type.name === ProfileTypes.PUBLISHER
                ) {
                  // setShowContactPublisherPage(true);
                  // stopGlobalLoader();
                  stopGlobalLoader();
                  history.push("/dashboard");
                  initSnackbarGeneric("Please contact your Publisher");
                  return;
                } else {
                  stopGlobalLoader();
                  history.push("/payments/manage-subscriptions");
                  // API.get(ApiUri.PACKAGE).then(
                  //   (response) => {
                  //     let allProducts = response.data.collection;
                  //     if (allProductsFromProfile.length === 0) {
                  //       allProducts = allProducts.filter(
                  //         (product) => product.is_basic
                  //       );
                  //     }
                  //     const filteredProducts = allProducts.filter(
                  //       (product) =>
                  //         product.profile_type.name ===
                  //         user?.last_accessed_profile.profile_type.name
                  //     );
                  //     setProductsAvailableForPurchase(filteredProducts);
                  //     setShowProductsPage(true);
                  //   },
                  //   (err) => {
                  //     history.push("/dashboard");
                  //     initSnackbarError(err);
                  //     stopGlobalLoader();
                  //   }
                  // );
                }
              },
              (err) => {
                history.push("/dashboard");
                initSnackbarError(err);
                stopGlobalLoader();
              }
            );
          }
        },
        (err) => {
          history.push("/dashboard");
          initSnackbarError(err);
          stopGlobalLoader();
        }
      );
    }
  }, []);

  return (
    showAddNewSongPage && (
      <React.Fragment>
        <SongWritersPercentageShareContextProvider composition={composition}>
          {/* this was one line up */}
          {/* {showContactPublisherPage ? (
          <p>Please contact your Publisher</p>
        ) : showProductsPage ? (
          <ProductsPage products={productsAvailableForPurchase} />
        ) : ( */}
          <Stack direction={"row"} justifyContent={"center"}>
            <Stepper sx={{ width: "50%" }} activeStep={0} alternativeLabel>
              <Step>
                <StepLabel StepIconComponent={LyricsRounded}>
                  <Typography fontWeight={"bold"}>Composition</Typography>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel
                  // adjust this style and put this stepper also on the manage song page
                  sx={{ color: "#a0a0a0" }}
                  StepIconComponent={VolumeUpRounded}
                >
                  <Typography fontWeight={400} color={"#a0a0a0"}>
                    Master Recording
                  </Typography>
                </StepLabel>
              </Step>
            </Stepper>
          </Stack>
          <CompositionForm
            mode="create"
            composition={composition}
            setComposition={setComposition}
          />
        </SongWritersPercentageShareContextProvider>
        {/* )} */}
      </React.Fragment>
    )
  );
}
