import React, { useState, useRef } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import { Song } from "../../../../../models/song/song";
import FileUpload from "../../../../../components/utilities/file-upload";
import FileDownload from "../../../../../components/utilities/file-download";
import AudioPlayer from "react-h5-audio-player";
import { PlayCircleFilled } from "@mui/icons-material";
import FileRemove from "../../../../../components/utilities/file-remove";
import ApiUri from "../../../../../api/api-uri";
import Songs from "../../../../../enums/songs";

interface IFormStepTwo {
  song: Song;
  setSong: Function;
}

export default function FormStepwo({ song, setSong }: IFormStepTwo) {
  // Track the currently playing audio
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handlePlay = (audioElement: HTMLAudioElement) => {
    // If another audio is already playing, pause it
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
    }
    // Set the new audio element as the currently playing one
    setCurrentAudio(audioElement);
  };

  const renderAudioPlayer = (src: string) => {
    const audioRef = React.createRef<AudioPlayer>();

    return (
      <AudioPlayer
        style={{ marginBottom: 10 }}
        autoPlayAfterSrcChange={false}
        showSkipControls={false}
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        src={src}
        customIcons={{
          play: (
            <PlayCircleFilled color="primary" style={{ fontSize: "1em" }} />
          ),
        }}
        onPlay={() => {
          // Handle play by calling the handlePlay function
          if (audioRef.current?.audio.current) {
            handlePlay(audioRef.current.audio.current);
          }
        }}
        ref={audioRef}
      />
    );
  };

  const buildSongVersionUri = (version: string) => {
    return `${ApiUri.SONG}/${song.id}${ApiUri.VERSION}?version=${version}`;
  };

  return (
    <form>
      <Grid container spacing={4}>
        {/* Full Song Version */}
        <Grid item xs={12}>
          <Typography variant="overline">Full Version *</Typography>
        </Grid>
        <Grid item xs={12}>
          {song.full_song_version ? (
            <>
              {renderAudioPlayer(song.full_song_version.file.url)}
              <FileDownload file={song.full_song_version?.file} />
              <FileRemove
                callback={() => {
                  /* handle remove function */
                }}
              />
            </>
          ) : (
            <FileUpload
              objectFQCN="SONG"
              objectId={song.id}
              callback={(data) =>
                setSong((prevState) => ({
                  ...prevState,
                  full_song_version: data,
                }))
              }
              uri={buildSongVersionUri(Songs.SONG_VERSION_FULL)}
              accept=".wav"
            />
          )}
        </Grid>

        {/* Instrumental Song Version */}
        <Grid item xs={12}>
          <Typography variant="overline">Instrumental Version</Typography>
        </Grid>
        <Grid item xs={12}>
          {song.instrumental_song_version ? (
            <>
              {renderAudioPlayer(song.instrumental_song_version.file.url)}
              <FileDownload file={song.instrumental_song_version?.file} />
              <FileRemove
                callback={() => {
                  /* handle remove function */
                }}
              />
            </>
          ) : (
            <FileUpload
              objectFQCN="SONG"
              objectId={song.id}
              callback={(data) =>
                setSong((prevState) => ({
                  ...prevState,
                  instrumental_song_version: data,
                }))
              }
              uri={buildSongVersionUri(Songs.SONG_VERSION_INSTRUMENTAL)}
              accept=".wav"
            />
          )}
        </Grid>

        {/* Extra Instrumental Song Version */}
        <Grid item xs={12}>
          <Typography variant="overline">Extra Instrumental Version</Typography>
        </Grid>
        <Grid item xs={12}>
          {song.extra_instrumental_song_version ? (
            <>
              {renderAudioPlayer(song.extra_instrumental_song_version.file.url)}
              <FileDownload file={song.extra_instrumental_song_version?.file} />
              <FileRemove
                callback={() => {
                  /* handle remove function */
                }}
              />
            </>
          ) : (
            <FileUpload
              objectFQCN="SONG"
              objectId={song.id}
              callback={(data) =>
                setSong((prevState) => ({
                  ...prevState,
                  extra_instrumental_song_version: data,
                }))
              }
              uri={buildSongVersionUri(Songs.SONG_VERSION_EXTRA_INSTRUMENTAL)}
              accept=".wav"
            />
          )}
        </Grid>

        {/* Clean Song Version */}
        <Grid item xs={12}>
          <Typography variant="overline">Clean Version</Typography>
        </Grid>
        <Grid item xs={12}>
          {song.clean_song_version ? (
            <>
              {renderAudioPlayer(song.clean_song_version.file.url)}
              <FileDownload file={song.clean_song_version?.file} />
              <FileRemove
                callback={() => {
                  /* handle remove function */
                }}
              />
            </>
          ) : (
            <FileUpload
              objectFQCN="SONG"
              objectId={song.id}
              callback={(data) =>
                setSong((prevState) => ({
                  ...prevState,
                  clean_song_version: data,
                }))
              }
              uri={buildSongVersionUri(Songs.SONG_VERSION_CLEAN)}
              accept=".wav"
            />
          )}
        </Grid>
      </Grid>
    </form>
  );
}
