import API from "../../api";
import ApiUri from "../../api-uri";

export const getMessages = async ({ profileId }) => {
  try {
    const response = API.get(
      ApiUri.PROFILE + "/" + profileId + ApiUri.MESSAGE,
      {
        params: {
          limit: 100000,
          page: 1,
          search_term: null,
        },
      }
    );

    return response;
  } catch (err) {
    throw err;
  }
};
