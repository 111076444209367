import React, { createContext, useState } from "react";
import { Message } from "../models/message/notification";

export interface IMessagesContext {
  messages: Array<Message>;
  setMessages: Function;
}

export const MessagesContext = createContext({} as IMessagesContext);

const MessagesContextProvider = ({ children }: any) => {
  const [messages, setMessages] = useState([]);

  // useEffect(() => {
  //   API.get(ApiUri.USER_ME).then((response: AxiosResponse) => {
  //     const user = response.data;
  //     handleNotificationsData(response.data.notifications);

  //     const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
  //       cluster: "eu",
  //     });

  //     const channel = pusher.subscribe("notifications");
  //     const event = "user-" + user?.id;

  //     channel.bind(event, (data) => {
  //       getNotifications();
  //     });
  //   });
  // }, []); // eslint-disable-line

  // const getNotifications = () => {
  //   API.get(ApiUri.USER_ME + ApiUri.NOTIFICATIONS).then((response) => {
  //     handleNotificationsData(response.data.collection);
  //   });
  // };

  // const handleNotificationsData = (data) => {
  //   setMessages(data);

  //   let unreadCount = 0;
  //   data.map((notification: Notification) => {
  //     return !notification.is_read ? unreadCount++ : null;
  //   });
  // };

  return (
    <MessagesContext.Provider
      value={{
        messages: messages,
        setMessages: setMessages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesContextProvider;
