import {
  Paper,
  Typography,
  Divider,
  Grid,
  Chip,
  TextField,
  Box,
  Stack,
} from "@mui/material";
import { PlayCircleFilled } from "@mui/icons-material";
import React, { useState, useRef } from "react";
import H2 from "../../../../components/layout/titles/h2";
import FileDownload from "../../../../components/utilities/file-download";
import { Instrument } from "../../../../models/song/instrument";
import { LyricalTheme } from "../../../../models/song/lyrical-theme";
import { Genre } from "../../../../models/song/tags/genre";
import { Mood } from "../../../../models/song/tags/mood";
import { Tempo } from "../../../../models/song/tags/tempo";
import { Vocals } from "../../../../models/song/tags/vocals";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { Composition } from "../../../../models/composition/composition";

interface ISongsCard {
  composition: Composition;
}

export default function SongsCard({ composition }: ISongsCard) {
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handlePlay = (audioElement: HTMLAudioElement) => {
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
    }
    setCurrentAudio(audioElement);
  };

  const renderAudioPlayer = (src: string) => {
    const audioRef = React.createRef<AudioPlayer>();

    return (
      <AudioPlayer
        style={{ marginBottom: 10 }}
        autoPlayAfterSrcChange={false}
        showSkipControls={false}
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        src={src}
        customIcons={{
          play: (
            <PlayCircleFilled color="primary" style={{ fontSize: "1em" }} />
          ),
        }}
        onPlay={() => {
          if (audioRef.current?.audio.current) {
            handlePlay(audioRef.current.audio.current);
          }
        }}
        ref={audioRef}
      />
    );
  };

  return (
    <Paper elevation={3} style={{ padding: 20, marginTop: 20 }}>
      <H2>Master Recordings</H2>
      {composition.songs.map((song) => (
        <React.Fragment key={song.id}>
          <Typography variant="h5" color="primary">
            {song.release_name}
          </Typography>

          <Divider />

          <Grid container style={{ marginTop: 10 }}>
            {song.artwork && (
              <Grid item xs={12}>
                <Box width={"200px"}>
                  <img
                    src={song.artwork?.file.url}
                    alt=""
                    style={{ maxWidth: 200 }}
                  />
                </Box>
                <FileDownload file={song.artwork?.file} />
              </Grid>
            )}
          </Grid>

          {/* Song Details (Instruments, Lyrical Themes, etc.) */}
          <Grid container style={{ marginTop: 10 }}>
            {/* Display Instruments */}
            <Grid item xs={12}>
              <Typography variant="overline">Instruments</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.instruments.map((instrument: Instrument) => (
                <Chip
                  key={instrument.id}
                  label={instrument.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            {/* Display Lyrical Themes */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Lyrical Themes</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.lyrical_themes.map((lyricalTheme: LyricalTheme) => (
                <Chip
                  key={lyricalTheme.id}
                  label={lyricalTheme.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            {/* Display Mood */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Mood</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.moods.map((mood: Mood) => (
                <Chip
                  key={mood.id}
                  label={mood.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            {/* Display Genre */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Genre</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.genres.map((genre: Genre) => (
                <Chip
                  key={genre.id}
                  label={genre.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            {/* Display Tempo */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Tempo</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.tempos.map((tempo: Tempo) => (
                <Chip
                  key={tempo.id}
                  label={tempo.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>

            {/* Display Vocals */}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography variant="overline">Vocals</Typography>
            </Grid>
            <Grid item xs={12}>
              {song.vocals.map((vocals: Vocals) => (
                <Chip
                  key={vocals.id}
                  label={vocals.name}
                  style={{ marginRight: 10 }}
                />
              ))}
            </Grid>
          </Grid>

          {/* Song Meta Details */}
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Release Name"
                value={song.release_name || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="ISRC"
                value={song.isrc || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                multiline
                label="Artist Name"
                value={
                  song.artists
                    ? song.artists.map((item) => item.name).join("\n")
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                multiline
                label="Release Date"
                value={
                  song.release_date
                    ? new Date(song.release_date).toLocaleDateString()
                    : ""
                }
                InputProps={{ readOnly: true }}
                rows={song.artists.length} // matching height of artists field
              />
            </Grid>

            {/* Additional Details */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Record Label Name"
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Barcode"
                value={song.barcode || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Duration"
                value={song.duration || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="BPM"
                value={song.bpm || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Is Instrumental?"
                value={composition?.is_instrumental ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                label="Is PPL Registered?"
                value={song.is_ppl_registered ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Song Versions (Full, Instrumental, Extra, Clean) */}
            <Grid item xs={12}>
              {song.full_song_version ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="overline">Full version</Typography>
                  </Grid>
                  {renderAudioPlayer(song.full_song_version.file.url)}
                  <FileDownload file={song.full_song_version?.file} />
                </>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {song.instrumental_song_version ? (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Instrumental version
                    </Typography>
                  </Grid>
                  {renderAudioPlayer(song.instrumental_song_version.file.url)}
                  <FileDownload file={song.instrumental_song_version?.file} />
                </>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {song.extra_instrumental_song_version ? (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Extra instrumental version
                    </Typography>
                  </Grid>
                  {renderAudioPlayer(
                    song.extra_instrumental_song_version.file.url
                  )}
                  <FileDownload
                    file={song.extra_instrumental_song_version?.file}
                  />
                </>
              ) : null}
            </Grid>

            <Grid item xs={12}>
              {song.clean_song_version ? (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">Clean version</Typography>
                  </Grid>
                  {renderAudioPlayer(song.clean_song_version.file.url)}
                  <FileDownload file={song.clean_song_version?.file} />
                </>
              ) : null}
            </Grid>
          </Grid>
        </React.Fragment>
      ))}
    </Paper>
  );
}
