import {
  Box,
  debounce,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/auth";
import RoleTypes from "../../../enums/role-types";
import { SnackbarContext } from "../../../context/snackbar";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../../context/global-loader";
import ActionButtonSmall from "../../../components/layout/buttons/action-button-small";
import RegisterNewTransactionModal from "./RegisterNewTransactionModal";
import ApiUri from "../../../api/api-uri";
import API from "../../../api/api";
import RoyaltiesTable from "./RoyaltiesTable";
import { Search } from "@mui/icons-material";
import { processFileNameForInvoices } from "./context-menu";

const RoyaltyPayments = () => {
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);

  const [royalties, setRoyalties] = useState(null);
  const [showRoyaltyPaymentsPage, setShowRoyaltyPaymentsPage] = useState(false);
  const [royaltiesDue, setRoyaltiesDue] = useState(0);
  const [
    registerNewTransactionModalOpened,
    setRegisterNewTransactionModalOpened,
  ] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const seeThisPage =
    userRole === RoleTypes.OWNER ||
    userRole === RoleTypes.ADMIN ||
    user?.is_mmpz_service_admin;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowRoyaltyPaymentsPage(true);
    }

    if (royalties === null) {
      // startGlobalLoader();
      API.get(
        ApiUri.PROFILE +
          "/" +
          user?.last_accessed_profile?.id +
          ApiUri.ROYALTY_ADJUST,
        {
          params: {
            limit: rowsPerPage,
            page: page + 1,
            search_term: searchTerm,
          },
        }
      ).then(
        (response) => {
          const royalties = response.data.collection;
          const totalIncomeSum = royalties.reduce((sum, item) => {
            return sum + (item.profile_earnings || 0);
          }, 0);
          const totalPaymentsSum = royalties.reduce((sum, item) => {
            return sum + (item.payments || 0);
          }, 0);
          setTotalRows(response.data.meta.total);
          setRoyaltiesDue(totalIncomeSum - totalPaymentsSum);
          setRoyalties(royalties);
          // stopGlobalLoader();
        },
        (error) => {
          initSnackbarError(error);
          // stopGlobalLoader();
        }
      );
    }
  }, [royalties]);

  const handleDownloadReports = () => {
    const selectedRoyalties = selectedRows.map((index) => royalties![index]);
    startGlobalLoader();

    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.ROYALTY_DOWNLOAD_ERNINGS_PAYMENTS,
      {
        consolidation_array: selectedRoyalties,
      }
    ).then(
      (response) => {
        // const downloadUrl = response.data?.url;
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.download = response?.data?.original_name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
          // type: "text/csv",
        });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download =
          response?.data?.original_name ||
          processFileNameForInvoices(selectedRoyalties, user);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(downloadUrl);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setSelectedRows([]);
    setRoyalties(null);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSelectedRows([]);
    setRoyalties(null);
  };

  const debouncedSearchTermInput = useCallback(
    debounce(() => {
      setPage(0);
      setSelectedRows([]);
      setRoyalties(null);
    }, 800),
    []
  );

  const handleChangeSearchBy = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearchTermInput();
  };

  const handleDownloadAllReports = () => {
    startGlobalLoader();

    API.post(
      ApiUri.PROFILE +
        "/" +
        user?.last_accessed_profile.id +
        ApiUri.ROYALTY_DOWNLOAD_ERNINGS_PAYMENTS,
      {
        consolidation_array: royalties,
      }
    ).then(
      (response) => {
        // const downloadUrl = response.data?.url;
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.download = response?.data?.original_name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
          // type: "text/csv",
        });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download =
          response?.data?.original_name ||
          processFileNameForInvoices(royalties, user);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(downloadUrl);
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error);
        stopGlobalLoader();
      }
    );
  };

  return showRoyaltyPaymentsPage ? (
    <>
      <Stack spacing={5}>
        <Paper
          elevation={3}
          sx={{ display: "inline-block", width: "fit-content" }}
        >
          <Stack spacing={2} p={2} width="fit-content">
            <Typography>Royalties Due</Typography>
            <Typography
              fontSize={20}
              color={royaltiesDue < 0 ? "red" : "green"}
            >
              {"GBP " + royaltiesDue.toFixed(2)}
            </Typography>
          </Stack>
        </Paper>
        <Stack direction={"row"} spacing={2}>
          {user?.is_mmpz_service_admin && (
            <ActionButtonSmall
              onClick={() => {
                setRegisterNewTransactionModalOpened(true);
              }}
            >
              Register New Transaction
            </ActionButtonSmall>
          )}
          <ActionButtonSmall
            onClick={handleDownloadReports}
            disabled={selectedRows.length === 0}
          >
            Download Reports
          </ActionButtonSmall>
          <ActionButtonSmall
            onClick={handleDownloadAllReports}
            disabled={royalties ? (royalties as any).length === 0 : true}
          >
            Download All Earnings Report
          </ActionButtonSmall>
        </Stack>
        <Stack spacing={2}>
          <Typography fontSize={20}>Royalties Overview</Typography>
          <Box display="flex" flexDirection="row-reverse">
            <TextField
              label="Search"
              focused={true}
              value={searchTerm}
              onChange={handleChangeSearchBy}
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          {/* {royalties && ( */}
          <RoyaltiesTable
            royalties={royalties}
            setRoyalties={setRoyalties}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            totalRows={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          {/* )} */}
        </Stack>
      </Stack>
      <RegisterNewTransactionModal
        modalOpened={registerNewTransactionModalOpened}
        handleClose={() => {
          setRegisterNewTransactionModalOpened(false);
        }}
        setRoyalties={setRoyalties}
      />
    </>
  ) : (
    <></>
  );
};

export default RoyaltyPayments;
