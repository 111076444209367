import React, { useContext } from "react";
import { MessagesContext } from "../context/messages";
import { Box, Stack } from "@mui/material";

const BannerMessages = () => {
  const { messages } = useContext(MessagesContext);
  const currentDate = new Date();

  const messagesToBeShown = messages
    .filter((message) => !message.popup)
    .filter(
      (message) =>
        currentDate >= new Date(message.visible_from) &&
        currentDate <= new Date(message.visible_until)
    );
  console.log("🚀 ~ BannerMessages ~ messagesToBeShown:", messagesToBeShown);
  return (
    <Stack width={"100%"} paddingTop={"28px"}>
      {messagesToBeShown.map((message, index) => (
        <Box
          key={`message-${index}`}
          sx={{
            backgroundColor: index % 2 === 0 ? "yellow" : "#FFF468",
            width: "100%",
          }}
          p={1}
        >
          {message?.content}
        </Box>
      ))}
    </Stack>
  );
};

export default BannerMessages;
