import React, { useState } from "react";
import { Box, Grid, Paper, TextField, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import { Song } from "../../../../models/song/song";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import FileDownload from "../../../../components/utilities/file-download";
import { PlayCircleFilled } from "@mui/icons-material";
import { Composition } from "../../../../models/composition/composition";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridItem: {
      padding: "20px",
    },
    textField: {
      marginBottom: "25px",
      padding: "5px",
    },
  })
);

interface ISongCard {
  song: Song;
  composition: Composition;
}

export default function SongsCard({ song, composition }: ISongCard) {
  const classes = useStyles();

  // Track the index of the currently playing audio
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handlePlay = (audioElement: HTMLAudioElement) => {
    // Pause the currently playing audio if any
    if (currentAudio && currentAudio !== audioElement) {
      currentAudio.pause();
    }
    // Set the new audio element as the current one
    setCurrentAudio(audioElement);
  };

  const renderAudioPlayer = (src: string) => {
    const audioRef = React.createRef<AudioPlayer>();

    return (
      <AudioPlayer
        style={{ marginBottom: 10 }}
        autoPlayAfterSrcChange={false}
        showSkipControls={false}
        showJumpControls={false}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        layout="horizontal-reverse"
        src={src}
        customIcons={{
          play: (
            <PlayCircleFilled color="primary" style={{ fontSize: "1em" }} />
          ),
        }}
        onPlay={() => {
          // Use the `audio` property of the `AudioPlayer` component
          if (audioRef.current?.audio.current) {
            handlePlay(audioRef.current.audio.current);
          }
        }}
        ref={audioRef}
      />
    );
  };

  return song ? (
    <Grid item xs={12} className={classes.gridItem}>
      <Paper elevation={3} style={{ height: "100%" }}>
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Release Name"
                value={song.release_name || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="ISRC"
                value={song.isrc || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Artist Name"
                value={
                  song.artists
                    ? song.artists.map((item) => item.name).join(", ")
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Release Date"
                value={
                  song.release_date
                    ? new Date(song.release_date).toLocaleDateString()
                    : ""
                }
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Record Label Name"
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Barcode"
                value={song.barcode || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Duration"
                value={song.duration || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="BPM"
                value={song.bpm || ""}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Is Instrumental?"
                value={composition?.is_instrumental ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                className={classes.textField}
                label="Is PPL Registered?"
                value={song.is_ppl_registered ? "Yes" : "No"}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            {/* Full Song Version */}
            <Grid item xs={12}>
              {song.full_song_version && (
                <>
                  <Grid item xs={12}>
                    <Typography variant="overline">Full version</Typography>
                  </Grid>
                  {renderAudioPlayer(song.full_song_version.file.url)}
                  <FileDownload file={song.full_song_version?.file} />
                </>
              )}
            </Grid>

            {/* Instrumental Song Version */}
            <Grid item xs={12}>
              {song.instrumental_song_version && (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Instrumental version
                    </Typography>
                  </Grid>
                  {renderAudioPlayer(song.instrumental_song_version.file.url)}
                  <FileDownload file={song.instrumental_song_version?.file} />
                </>
              )}
            </Grid>

            {/* Extra Instrumental Song Version */}
            <Grid item xs={12}>
              {song.extra_instrumental_song_version && (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">
                      Extra instrumental version
                    </Typography>
                  </Grid>
                  {renderAudioPlayer(
                    song.extra_instrumental_song_version.file.url
                  )}
                  <FileDownload
                    file={song.extra_instrumental_song_version?.file}
                  />
                </>
              )}
            </Grid>

            {/* Clean Song Version */}
            <Grid item xs={12}>
              {song.clean_song_version && (
                <>
                  <Grid item xs={12} style={{ marginTop: 40 }}>
                    <Typography variant="overline">Clean version</Typography>
                  </Grid>
                  {renderAudioPlayer(song.clean_song_version.file.url)}
                  <FileDownload file={song.clean_song_version?.file} />
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  ) : null;
}
