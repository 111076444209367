import React, { useContext, useEffect, useState } from "react";
import ReleaseUnsubmissionsTable from "./release-unsubmissions-table";
import { AuthContext } from "../../../context/auth";
import { SnackbarContext } from "../../../context/snackbar";
import ProfileTypes from "../../../enums/profile-types";
import { useHistory } from "react-router-dom";

export default function ReleaseUnsubmissions() {
  const [showReleaseUnsubmissionsPage, setShowReleaseUnsubmissionsPage] =
    useState(false);
  const { user, userRole } = useContext(AuthContext);
  const { initSnackbarError } = useContext(SnackbarContext);
  const history = useHistory();

  const seeThisPage =
    user?.is_mmpz_service_admin &&
    user?.last_accessed_profile?.profile_type?.name === ProfileTypes.SERVICE;

  useEffect(() => {
    if (!seeThisPage) {
      initSnackbarError("You don't have permission to access this page.");
      history.push("/dashboard");
      return;
    } else {
      setShowReleaseUnsubmissionsPage(true);
    }
  }, []);

  return (
    showReleaseUnsubmissionsPage && (
      <React.Fragment>
        <ReleaseUnsubmissionsTable />
      </React.Fragment>
    )
  );
}
